<template>
  <div>
    <b-alert :show="showAlert" variant="danger">
      <span>{{ alertMsg }}</span>
    </b-alert>
    <div class="row">
      <div class="col-md-9">
        <div class="card card-custom gutter-b">
          <div class="card-body px-2">
            <DualListBoxDevices
              :source="devices"
              :destination="selectedDevices"
              label="name"
              @onChangeList="onChangeList"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              v-model="range"
              mode="dateTime"
              :attributes="attributes"
              :masks="masks"
              locale="hr"
              is-range
              is24hr
              is-expanded
              :columns="$screens({ default: 1, lg: 1 })"
              :max-date="new Date()"
            />
            <button
              @click="last7Days"
              class="btn btn-light-primary btn-block font-weight-bold mt-2"
            >
              Last 7 days
            </button>
            <button
              @click="last30Days"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last 30 days
            </button>
            <button
              @click="thisMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              This month
            </button>
            <button
              @click="lastMonth"
              class="btn btn-light-primary btn-block font-weight-bold"
            >
              Last month
            </button>
            <button
              @click="reset"
              class="btn btn-light-danger btn-block font-weight-bold"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12">
        <div class="pt-4">
          <button
            v-if="!isGenerated"
            class="btn btn-primary btn-block font-weight-bold"
            @click="generate"
          >
            Generate
          </button>
          <button
            v-else
            class="btn btn-primary btn-block font-weight-bold"
            @click="resetAll"
          >
            Reset All
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <div
            v-if="isLoading"
            class="col-12 text-center my-5 p-2 pt-5 bg-secondary"
          >
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
            </div>
          </div>
          <table
            v-else-if="deposits.length && !isLoading && isGenerated"
            class="table table-head-custom table-vertical-center table-head-bg"
          >
            <thead>
              <tr class="bg-gray-100 text-left">
                <th>{{ $t('DISCHARGE.DISCHARGE_TIME') }}</th>
                <th>{{ $t('DEPOSITS.DEVICE_NAME') }}</th>
                <th>{{ $t('GENERAL.LOCATION') }}</th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-center"
                >
                  {{ $t('DEPOSITS.DEPOSIT') }}
                </th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-center"
                >
                  {{ $t('DEPOSITS.REPORTED') }}
                </th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-center"
                >
                  {{ $t('DEPOSITS.DIFFERENCE') }}
                </th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-center"
                >
                  {{ $t('DEPOSITS.TOKEN_DEPOSIT') }}
                </th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-center"
                >
                  {{ $t('DEPOSITS.TOKEN_REPORTED') }}
                </th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-center"
                >
                  {{ $t('DEPOSITS.TOKEN_DIFFERENCE') }}
                </th>
                <th
                  v-if="
                    currentUserRole === 'ROLE_SUPER_ADMIN' ||
                    (currentUserRole === 'ROLE_ADMIN' &&
                      currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                  "
                  class="text-right pr-6"
                >
                  {{ $t('GENERAL.USER') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in deposits" :key="key">
                <td>
                  <span v-if="item.createdAt">{{
                    item.createdAt | formatDateTime
                  }}</span>
                  <span v-else></span>
                </td>
                <td
                  class="font-weight-bolder mt-4"
                  style="
                    width: fit-content;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: row;
                    justify-content: center;
                  "
                >
                  <p>
                    <span v-if="item.device.name">{{ item.device.name }}</span>
                    <span v-else></span>
                  </p>
                  <p>
                    <span
                      v-if="item.device.businessSpaceLabel"
                      class="label label-sm label-light-primary label-inline"
                    >
                      {{ item.device.businessSpaceLabel }}
                    </span>
                    <span v-else></span>
                  </p>
                </td>
                <td>
                  <span v-if="item.device.location.title">{{
                    item.device.location.title
                  }}</span>
                  <span v-else></span>
                </td>
                <td class="text-center">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span v-if="item.amount">{{ item.amount }} €</span>
                    <span v-else>/</span>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span v-if="item.reportedAmount"
                      >{{ item.reportedAmount }} €</span
                    >
                    <span v-else>/</span>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span v-if="item.reportedAmount && item.amount"
                      >{{
                        parseFloat(
                          (item.reportedAmount - item.amount).toFixed(2),
                          2
                        )
                      }}
                      €</span
                    >
                    <span v-else>/</span>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span v-if="!!item.tokenAmount">{{
                      item.tokenAmount
                    }}</span>
                    <span v-else>/</span>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span v-if="!!item.reportedTokenAmount">{{
                      item.reportedTokenAmount
                    }}</span>
                    <span v-else>/</span>
                  </div>
                </td>
                <td class="text-center">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span
                      v-if="!!item.reportedTokenAmount && !!item.tokenAmount"
                    >
                      {{
                        parseFloat(
                          (item.tokenAmount - item.reportedTokenAmount).toFixed(
                            0
                          ),
                          0
                        )
                      }}
                    </span>
                    <span v-else>/</span>
                  </div>
                </td>
                <td class="text-right pr-6">
                  <div
                    v-if="
                      currentUserRole === 'ROLE_SUPER_ADMIN' ||
                      (currentUserRole === 'ROLE_ADMIN' &&
                        currentUserPersonalInfo.email.includes('@kidsdelux.hr'))
                    "
                  >
                    <span>
                      {{ item.user && item.user.name ? item.user.name : '/' }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-else-if="deposits.length === 0 && !isLoading && isGenerated"
            class="text-center mt-5 mb-5 pt-5"
          >
            <h4>{{ $t('DEPOSITS.NO_DATA') }}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import DualListBoxDevices from '@/view/content/components/dual-list-box/DualListBoxDevices'
import ApiService from '@/core/services/api.service'
import add from 'date-fns/add'
import format from 'date-fns/format'
import startOfMonth from 'date-fns/startOfMonth'
import getMonth from 'date-fns/getMonth'
import addMonths from 'date-fns/addMonths'
import getYear from 'date-fns/getYear'
import endOfMonth from 'date-fns/endOfMonth'
import addDays from 'date-fns/addDays'

export default {
  name: 'Devices Discharge History',
  components: {
    DatePicker,
    DualListBoxDevices,
  },
  data() {
    return {
      devices: [],
      selectedDevices: [],
      generatedResults: [],
      deposits: [],
      resultsDays: [],
      totalItems: null,
      range: {
        start: add(new Date(), { days: -1 }).setHours(24, 0, 0),
        end: new Date().setHours(23, 59, 59),
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      showAlert: false,
      alertMsg: null,
      base: 0,
      tax: 0,
      total: 0,
      isGenerated: false,
      showSpinner: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentActiveModuleComponents',
      'currentUserPersonalInfo',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Discharge History', route: '/devices/discharge-history' },
    ])

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      ApiService.get(
        'select-device',
        `?companyId=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}`
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = data
        this.devices = this.devices.filter(
          (device) =>
            (device.isSaldoReducer !== true) & (device.isDemoDevice !== true)
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.location + ' | ' + device.tag
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER'
    ) {
      ApiService.get(
        'devices',
        `?company=${this.currentUserCompany.replace(
          '/api/v1/companies/',
          ''
        )}&pagination=false&new=1&select=flags,timestamps`
      ).then(({ data }) => {
        this.totalItems = data.length
        this.devices = [...data['hydra:member']]
        this.devices = this.devices.filter(
          (device) => !device.isSaldoReducer & !device.isDemoDevice
        )
        this.devices.forEach((device, index) => {
          this.devices[index].name =
            device.name + ' | ' + device.locationName + ' | '
        })
      })
    }

    if (
      this.currentUserRole === 'ROLE_WATCHER' ||
      this.currentUserRole === 'ROLE_WATCHER_BILLS'
    ) {
      ApiService.get(
        'users',
        `?email=${this.currentUserPersonalInfo.email}`
      ).then(({ data }) => {
        const userLocations = data['hydra:member'][0]['locations']

        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&location.id=${userLocations[0].id}&pagination=false`
        ).then(({ data }) => {
          this.totalItems = data['hydra:member'].length
          this.devices = [...data['hydra:member']]
          this.devices = this.devices.filter(
            (device) =>
              (device.isSaldoReducer !== true) & (device.isDemoDevice !== true)
          )
        })
      })
    }
  },
  methods: {
    generate() {
      if (
        this.selectedDevices.length === 0 ||
        this.range.start === null ||
        this.range.end === null
      ) {
        this.alertMsg = 'Please select device/s & pick range'
        this.showAlert = true

        return
      }

      this.isLoading = true
      this.isGenerated = false
      this.generatedResults = []

      const after = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const before = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      this.selectedDevices.forEach((device) => {
        ApiService.get(
          'deposits',
          `?device=${device.id}&createdAt[after]=${after}&createdAt[before]=${before}&order[createdAt]=desc&pagination=false`
        )
          .then(({ data }) => {
            data['hydra:member'].forEach((deposit) => {
              this.deposits.push(deposit)
            })
          })
          .finally(() => {
            this.isLoading = false
            this.isGenerated = true
          })
      })
    },
    last7Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -8 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    last30Days() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: add(new Date(), { days: -31 }).setHours(24, 0, 0),
        end: add(new Date(), { days: -1 }).setHours(23, 59, 59),
      })
    },
    thisMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      const today = new Date()

      this.$refs.picker.updateValue({
        start: startOfMonth(today),
        end: today,
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 2,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    lastMonth() {
      this.$refs.picker.updateValue({ start: null, end: null })

      this.$refs.picker.updateValue({
        start: startOfMonth(addMonths(new Date(), -1)),
        end: endOfMonth(addMonths(new Date(), -1)),
      })

      this.$refs.picker.move({
        month: getMonth(addMonths(new Date(), -1)) + 1,
        year: getYear(addMonths(new Date(), -1)),
      })
    },
    reset() {
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    resetAll() {
      this.generatedResults = []
      this.resultsDays = []
      this.totalItems = null
      this.range.start = null
      this.range.end = null
      this.base = 0
      this.tax = 0
      this.total = 0
      this.isGenerated = false
      this.showSpinner = false
      this.isLoaded = false
      this.$refs.picker.updateValue({ start: null, end: null })
    },
    onChangeList: function ({ source, destination }) {
      this.devices = source
      this.selectedDevices = destination
    },
    setStartEndDate() {
      let a = addDays(this.range.start, -1)
      a.setHours(24, 0, 0)
      this.range.start = a

      let b = addDays(this.range.end, 0)
      b.setHours(23, 59, 59)
      this.range.end = b

      this.$refs.picker.updateValue({
        start: this.range.start,
        end: this.range.end,
      })
    },
  },
}
</script>

<style lang="scss">
.card-title {
  display: flex;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
}
</style>
